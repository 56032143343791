import React from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass'
import orderBy from 'lodash/orderBy'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import ApartmentList from '../components/ApartmentList'
import AboutUs from '../components/AboutUs'
import ReviewList from '../components/ReviewList'
import Amenities from '../components/Amenities'
import Contact from '../components/Contact'
import SectionHeading from '../components/SectionHeading'

const Section = ({ heading = null, children, ...props }) => (
  <Box
    py={5}
    {...props}
    as="section"
  >
    <Container>
      {heading ? (
        <SectionHeading>
          {heading}
        </SectionHeading>
      ) : null}
      {children}
    </Container>
  </Box>
)

const HomeTemplate = ({ data, pageContext }) => {
  const t = data.translations.childTranslationsJson
  const content = data.content.childContentJson
  const reviews = data.fiveStarReviews.edges

  return (
    <Layout
      seo={{
        title: t.meta.title,
        description: t.meta.description,
        lang: pageContext.language,
        shareImage: data.shareImage.childImageSharp.fixed
      }}
      translations={t}
      contactInfo={content}
      apartments={content.apartments}
    >
      <PageHeader
        variant="large"
        paths={pageContext.paths}
        title={t.meta.title}
        heroTitle={t.section.hero.title}
      />

      <Section heading={t.section.apartments.title}>
        <ApartmentList
          apartments={orderBy(content.apartments, ['area'], ['desc'])}
          images={data.apartmentPreviews.edges}
        />
      </Section>
      <Section bg="bg.gray" heading={t.section.aboutUs.title}>
        <AboutUs
          description={t.section.aboutUs.description}
          aboutUsImage={data.overviewImage.childImageSharp.fixed}
        />
      </Section>
      <Box as="section">
        <Amenities
          translations={t.section.amenities}
          amenitiesImage={data.amenitiesImage.childImageSharp.fluid}
        />
      </Box>
      {
        reviews && reviews.length
          ? (
            <Section heading={t.section.reviews.title}>
              <ReviewList
                reviews={reviews}
                reviewImages={data.reviewImages.edges}
              />
            </Section>
          ) : null
      }
      <Section
        heading={t.section.contact.title}
      >
        <Contact
          translations={t}
          contactInfo={content}
        />
      </Section>
    </Layout>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query HomeQuery($language: String!) {
    translations: file(
      name: { eq: $language },
      absolutePath: {regex: "/translations/"}
    ) {
      childTranslationsJson {
        ...CommonTranslationFields
        ...HomeTranslationFields
      }
    }

    content: file(
      name: { eq: $language },
      absolutePath: {regex: "/content/"}
    ) {
      childContentJson {
        address
        phoneNumber
        emailAddress
        apartments {
          id
          name
          path
          maxPeople
          roomCount
          area
          pageTitle
        }
      }
    }

    fiveStarReviews: allGoogleReview(
      limit: 3,
      filter: {
        rating: { gte: 5 }
        language: { eq: $language }
      },
    ) {
      edges {
        node {
          id
          text
          language
          authorName
        }
      }
    }

    amenitiesImage: file(
      name: { eq: "amenities" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 40) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    apartmentPreviews: allFile(
      filter: {
        relativePath: { regex: "/images/apartmentPreview/" }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 325, quality: 40) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    shareImage: file(
      name: { eq: "share" }
    ) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    overviewImage: file(
      name: { eq: "overview" }
    ) {
      childImageSharp {
        fixed(width: 450, quality: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    reviewImages: allFile(
      filter: {
        name: { regex: "/review/" }
      }
      sort: {
        fields: name
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 425, height: 275, quality: 50) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
